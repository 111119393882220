import * as React from 'react';
import PageHeader from "../components/PageHeader";
import Copyright from "../components/Copyright";
import {Box, Container} from "@mui/material";
import PageDefault from "../components/PageDefault";
import MuiLink from "@mui/material/Link";

export default function Help() {
    return (
        <>
            <PageDefault/>
            <PageHeader/>
            <Container maxWidth={"md"}>
                <Box pb={4} pt={4}>
                    <h1>MyGroupMate App Hilfe</h1>
                    <p>
                        Link zu unserer{' '}
                        <MuiLink color="inherit" href="/privacy-policy/">
                            Datenschutzerklärung
                        </MuiLink>
                    </p>
                    <h2>Account löschen</h2>
                    <p>
                        <ul>
                            <li>Senden Sie eine E-Mail an <a href={"mailto:info@groupmate-app.com"}>info@groupmate-app.com</a> mit den Daten Ihres Accounts (E-Mail Adresse, Vorname, Nachname)</li>
                            <li>Wir werden uns schnellstmöglich um Ihr Anliegen kümmern und ihren Account und alle gespeicherten Daten zu löschen</li>
                        </ul>
                    </p>
                    <p>
                        Es gelten die Rechte aus unserer {' '}
                        <MuiLink color="inherit" href="/privacy-policy/">
                            Datenschutzerklärung (Punkt 5)
                        </MuiLink>
                    </p>
                </Box>
            </Container>
            <Copyright/>
        </>
    );
}
